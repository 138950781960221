<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" md="12">
        <v-card :elevation="1" color="#26c6da2b">
          <v-card-text class="py-1">
            <v-row dense>
              <v-col cols="12" md="2">
                <v-combobox
                  label="Department"
                  dense
                  outlined
                  hide-details
                  :items="$store.getters['department/departments']"
                  v-model="department"
                  item-text="name"
                  item-value="id"
                  :loading="$store.getters['department/loadingDepartments']"
                  @focus="$store.dispatch('department/getDepartments')"
                  class="custom-form"
                ></v-combobox>
              </v-col>

              <v-col cols="12" md="2">
                <v-combobox
                  label="Designation"
                  dense
                  outlined
                  hide-details
                  :items="$store.getters['designation/designations']"
                  v-model="designation"
                  item-text="name"
                  item-value="id"
                  :loading="$store.getters['designation/loadingDesignations']"
                  @focus="$store.dispatch('designation/getDesignations')"
                  class="custom-form"
                ></v-combobox>
              </v-col>

              <v-col cols="12" md="2">
                <v-combobox
                  label="Shift"
                  dense
                  outlined
                  hide-details
                  :items="$store.getters['shift/shifts']"
                  v-model="shift"
                  item-text="name"
                  item-value="id"
                  :loading="$store.getters['shift/loadingShifts']"
                  @focus="$store.dispatch('shift/getShifts')"
                  class="custom-form"
                ></v-combobox>
              </v-col>

              <v-col cols="12" md="2">
                <v-combobox
                  label="Employee"
                  dense
                  outlined
                  hide-details
                  v-model="employee"
                  :items="$store.getters['employee/employees']"
                  :loading="$store.getters['employee/loadingEmployees']"
                  item-text="display_name"
                  item-value="id"
                  class="mb-1 custom-form"
                  @focus="getEmployees"
                ></v-combobox>
              </v-col>

              <v-col cols="6" md="1">
                <v-btn
                  elevation="1"
                  small
                  color="primary"
                  dark
                  style="margin-top: -2px"
                  :loading="loading"
                  @click="getReports"
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="records.length > 0">
      <v-col cols="12">
        <v-btn color="primary" class="white--text" small dense @click="print">
          <v-icon left small dense> mdi-printer </v-icon>
          Print
        </v-btn>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-toolbar dense color="white" :elevation="1" height="30px">
            <v-spacer></v-spacer>
            <v-toolbar-title class="subtitle-2">Wages & OT Sheet of {{salary.month_name}}</v-toolbar-title>    
            <v-spacer></v-spacer>       
        </v-toolbar>
      </v-col>
      <v-col cols="12" id="reportContent">
        <v-simple-table dense class="record_table">
          <template v-for="department in records">
            <thead>
              <tr>
                <th colspan="26" style="text-align: left; color: #0277bd">
                  {{ department.name }}
                </th>
              </tr>

              <tr class="record_heading_tr">
                <th rowspan="2">SL.</th>
                <th rowspan="2">E-ID</th>
                <th rowspan="2">Employee Name</th>
                <th rowspan="2">
                  Designation
                  <br />
                  Department
                  <br />
                  Joining Date
                </th>
                <!-- <th rowspan="2">Gross Salary</th> -->
                <th rowspan="2">Basic</th>
                <!-- <th rowspan="2">House Rent</th>
                <th rowspan="2">Medical</th>
                <th rowspan="2">Conveyance</th>
                <th rowspan="2">Others</th> -->
                <th rowspan="2">PF</th>
                <th colspan="6">Attendance</th>
                <th rowspan="2">LV</th>
                <th rowspan="2">LWP</th>
                <th rowspan="2">Total</th>
                <th colspan="3">Over Time</th>
                <th rowspan="2">Deduction</th>
                <th rowspan="2">Advance</th>
                <th rowspan="2">Benefit</th>
                <th rowspan="2">Loan</th>
                <th rowspan="2">Shift Allowance</th>
                <th rowspan="2">Net Payable</th>
                <th rowspan="2">Overtake Amount</th>
                <th rowspan="2" style="padding: 0px 30px !important;">Signature</th>
              </tr>

              <tr class="record_heading_tr">
                <th>P</th>
                <th>L</th>
                <th>LA</th>
                <th>A</th>
                <th>H</th>
                <th>WH</th>
                <th>Hrs.</th>
                <th>Rate</th>
                <th>Total</th>
              </tr>
            </thead>

            <tr v-for="salary in department.salaries" :key="salary.sl">
                <td>{{salary.sl}}</td>
                <td>{{salary.emp_id}}</td>
                <td>{{salary.employee_name}}</td>
                <td>
                    {{salary.designation_name}} <br>
                    {{salary.department_name}} <br>
                    {{salary.joining_date}}
                </td>
                <!-- <td>{{salary.gross_salary}}</td> -->
                <td>{{salary.basic_salary}}</td>
                <!-- <td>{{salary.house_rent_amount}}</td>
                <td>{{salary.medical_allowance_amount}}</td>
                <td>{{salary.conveyance_allowance_amount}}</td>
                <td>{{salary.others_allowance_amount}}</td> -->
                <td>{{salary.pf_amount}}</td>
                <td>{{salary.present}}</td>
                <td>{{salary.late}}</td>
                <td>{{salary.late_absent}}</td>
                <td>{{salary.absent}}</td>
                <td>{{salary.holidays}}</td>
                <td>{{salary.weekends}}</td>
                <td>{{salary.leave}}</td>
                <td>{{salary.leave_without_pay}}</td>
                <td>{{salary.total_days}}</td>
                <td>{{salary.ot_hour}}</td>
                <td>{{salary.ot_rate}}</td>
                <td>{{salary.ot_amount}}</td>
                <td>{{salary.deduction}}</td>
                <td>{{salary.advance}}</td>
                <td>{{salary.benefit}}</td>
                <td>{{salary.loan_received}}</td>
                <td>{{salary.shift_allowance}}</td>
                <td>{{salary.net_payable}}</td>
                <td>{{salary.overtake_amount}}</td>
                <td></td>
            </tr>
            <tr>
              <td colspan="4" style="text-align:right"><strong>Total</strong></td>
              <td style="text-align:right"><strong>{{ department.salaries.reduce((p, c) => { return +p + +c.basic_salary}, 0).toFixed(2) }}</strong></td>
              <td style="text-align:right"><strong>{{ department.salaries.reduce((p, c) => { return +p + +c.pf_amount}, 0).toFixed(2) }}</strong></td>
              <td style="text-align:right"><strong>{{ department.salaries.reduce((p, c) => { return +p + +c.present}, 0).toFixed(2) }}</strong></td>
              <td style="text-align:right"><strong>{{ department.salaries.reduce((p, c) => { return +p + +c.late}, 0).toFixed(2) }}</strong></td>
              <td style="text-align:right"><strong>{{ department.salaries.reduce((p, c) => { return +p + +c.late_absent}, 0).toFixed(2) }}</strong></td>
              <td style="text-align:right"><strong>{{ department.salaries.reduce((p, c) => { return +p + +c.absent}, 0).toFixed(2) }}</strong></td>
              <td style="text-align:right"><strong>{{ department.salaries.reduce((p, c) => { return +p + +c.holidays}, 0).toFixed(2) }}</strong></td>
              <td style="text-align:right"><strong>{{ department.salaries.reduce((p, c) => { return +p + +c.weekends}, 0).toFixed(2) }}</strong></td>
              <td style="text-align:right"><strong>{{ department.salaries.reduce((p, c) => { return +p + +c.leave}, 0).toFixed(2) }}</strong></td>
              <td style="text-align:right"><strong>{{ department.salaries.reduce((p, c) => { return +p + +c.leave_without_pay}, 0).toFixed(2) }}</strong></td>
              <td style="text-align:right"><strong>{{ department.salaries.reduce((p, c) => { return +p + +c.total_days}, 0).toFixed(2) }}</strong></td>
              <td style="text-align:right"><strong>{{ department.salaries.reduce((p, c) => { return +p + +c.ot_hour}, 0).toFixed(2) }}</strong></td>
              <td style="text-align:right"><strong>{{ department.salaries.reduce((p, c) => { return +p + +c.ot_rate}, 0).toFixed(2) }}</strong></td>
              <td style="text-align:right"><strong>{{ department.salaries.reduce((p, c) => { return +p + +c.ot_amount}, 0).toFixed(2) }}</strong></td>
              <td style="text-align:right"><strong>{{ department.salaries.reduce((p, c) => { return +p + +c.deduction}, 0).toFixed(2) }}</strong></td>
              <td style="text-align:right"><strong>{{ department.salaries.reduce((p, c) => { return +p + +c.advance}, 0).toFixed(2) }}</strong></td>
              <td style="text-align:right"><strong>{{ department.salaries.reduce((p, c) => { return +p + +c.benefit}, 0).toFixed(2) }}</strong></td>
              <td style="text-align:right"><strong>{{ department.salaries.reduce((p, c) => { return +p + +c.loan_received}, 0).toFixed(2) }}</strong></td>
              <td style="text-align:right"><strong>{{ department.salaries.reduce((p, c) => { return +p + +c.shift_allowance}, 0).toFixed(2) }}</strong></td>
              <td style="text-align:right"><strong>{{ department.salaries.reduce((p, c) => { return +p + +c.net_payable}, 0).toFixed(2) }}</strong></td>
              <td style="text-align:right"><strong>{{ department.salaries.reduce((p, c) => { return +p + +c.overtake_amount}, 0).toFixed(2) }}</strong></td>
            </tr>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import utility from "../../mixins/utility.mixin";
export default {
  mixins: [utility],
  data() {
    return {
      date_from: this.toISOLocal().substr(0, 10),
      date_to: this.toISOLocal().substr(0, 10),
      department: null,
      designation: null,
      shift: null,
      employee: null,
      salary: null,
      records: [],
      loading: false,
    };
  },
  async created() {
    await this.getSalaries();
    await this.getReports();
  },
  methods: {
    async getSalaries(){

        let salary = await this.$store.dispatch('salary/getSalaries',{
            apiParams: {
                id       : this.$route.params.id,
            },
            options: {
                returnData: true,
            }
        });

        this.salary = salary[0];
    },
    getEmployees() {
      this.employee = null;
      this.$store.dispatch("employee/getEmployees", {
        apiParams: {
          departmentId : this.department?.id ?? null,
          designationId: this.designation?.id ?? null,
          shiftId      : this.shift?.id ?? null,
        },
      });
    },
    async getReports() {
      this.records = [];
      this.loading = true;

      this.records = await this.$store.dispatch("salary/getSalaryStatements",{
          apiParams: {
            departmentId : this.department?.id ?? null,
            designationId: this.designation?.id ?? null,
            shiftId      : this.shift?.id ?? null,
            employeeId   : this.employee?.id ?? null,
            id           : this.$route.params.id,
          },
          options: {
            returnData: true,
          },
        }
      );

      this.loading = false;
    },
    async print() {
      let title = `Wages & OT Sheet of ${this.salary.month_name}`;

      let company_profile = this.$store.getters["company/company"];

      // Get HTML to print from element
      const prtHtml = document.getElementById("reportContent").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      // const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      const WinPrint = window.open(
        "",
        "PRINT",
        `height=${screen.height}, width=${screen.width}`
      );
      WinPrint.document.write(`<!DOCTYPE html>
        <html>
        <head>
            ${stylesHtml}
        </head>
        <body>
            <div data-app="true" class="v-application v-application--is-ltr theme--light" id="app">
                <div class="container container--fluid">
                    <div class="row row--dense">
                        <div class="col-2 px-2">
                            <img style="width: 100%; height: 50px;" src="${
                                this.$store.state.host +
                                company_profile.logo
                            }">
                        </div>
                        <div class="col-8 px-2 text-center">
                            <span class="font-weight-black">
                                ${company_profile.name}
                            </span>
                            <br>
                            <span>${company_profile.address}</span>
                            <br>
                            <span>
                                Email:${
                                    company_profile.email
                                }, Contact:${company_profile.phone}
                            </span>
                        </div>
                        <div class="col-2 px-2">
                            <img style="width: 100%; height: 50px;" src="${
                                this.$store.state.host + "linkup.png"
                            }">
                        </div>
                        <div class="col-12 px-2 text-center">
                            <p class="font-weight-black custom_header">
                                ${title}
                            </p>
                        </div>
                        <div class="col col-12 px-2">
                            ${prtHtml}
                        </div>
                    </div>
                </div>
            </div>
        </body>
        </html>`);

      WinPrint.document.head.innerHTML += `
        <style>
            .v-data-table__wrapper{
                overflow: unset;
            }
            .record_heading_tr th{
                text-align: center !important;
            }
            .custom_header{
                background-color: #0000001f;
                border-radius: 4px;
                margin-bottom: 5px !important;
            }
            tr:hover{
                background: unset !important;
            }
            .record_heading_tr:hover{
                background: #4caf7773 !important;
            }
            @media print {
                table.record_table { page-break-after:auto }
                table.record_table tr    { page-break-inside:avoid; page-break-after:auto }
                table.record_table td    { page-break-inside:avoid; page-break-after:auto }
                table.record_table thead { display:table-header-group }
                table.record_table tfoot { display:table-footer-group }
            }
        </style>`;

      WinPrint.focus();
      WinPrint.document.close();
      await new Promise((resolve) => setTimeout(resolve, 2000));
      WinPrint.print();
      WinPrint.close();
    },
  },
};
</script>

<style>
.record_table table,
.record_table th,
.record_table td {
  border: 1px solid #dee2e6;
  border-collapse: collapse;
  padding: 0 !important;
}
.record_table th,
.record_table td {
  padding: 2px !important;
  font-size: 11px !important;
  text-align: center;
  height: 0 !important;
}
.record_heading_tr {
  background: #4caf7773;
}
.record_heading_tr th{
  text-align: center !important;
}
</style>